import React, {
    useEffect,
    useState,
} from 'react';

import FreePassShape from '../assets/images/Shape/FreePassShape';
import Active from '../common/buttons/Active';
import DropdownBase from '../common/dropdowns/DropdownBase';
import InputBase from '../common/inputs/RebalanceInputBase';
import useFetch from '../hooks/useFetch';
import {
    FreePassToStrapiAPI,
    GetFreePassAPI,
} from '../utils/FetchData';
import Succes from './Modal/Succes';

function GetYourFreePass(props) {
    const { color, vector, image, title, desc, bgShape, isWhite } = props
    const [status, setStatus] = useState<boolean>(false)
    const { data: locations } = useFetch({ params: 'studios', populate: 'state' })

    const [userInfo, setUserInfo] = useState({
        venue_id: "1234",
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        city: "",
        state: "",
        interested_in: "Fitness, Personal Training",
        user_id: "1234",
        referred_by: "Friend",
        "Source Group": "Website",
        "Source Name": "Free Class",
        venue_name: "",
    })

    // Handle change value input
    const handleChangeValue = (e) => {
        const value = e.target.value
        setUserInfo((prevState) => ({ ...prevState, [e.target.name]: value }))
    }

    // Handle Submit
    const handleSubmit = async () => {
        const emptyFields = Object.entries(userInfo).filter(([key, value]) => value === '');
        if (emptyFields.length > 0) {
            const fieldNames = emptyFields.map(([key, value]) => key).join(', ');
            alert(`The following fields are required: ${fieldNames}`);
        } else {
            await GetFreePassAPI(userInfo);
            await FreePassToStrapiAPI(userInfo)
            setStatus(true)
        }
    }
    // Get state from studio
    useEffect(() => {
        let state = locations?.data?.filter(item => item?.attributes?.title == userInfo.city)?.[0]?.attributes?.state?.data?.attributes?.Name
        setUserInfo({ ...userInfo, state: state, venue_name: userInfo.city })
    }, [userInfo.city])

    // Cleanup Data after submit
    useEffect(() => {
        if (!status) {
            setUserInfo({
                venue_id: "1234", first_name: "", last_name: "", email: "", phone: "", city: "", state: "", interested_in: "Fitness, Personal Training", user_id: "1234", referred_by: "Friend", venue_name: "",
                "Source Group": "Website",
                "Source Name": "Free Class",
            })
        }
    }, [status])

    return (
        <div style={{ backgroundColor: color }}>
            <div
                className='desktop:h-[600px] desktop:w-[1440px] w-full mx-auto h-auto flex desktop:flex-row flex-col items-center justify-between lg:py-[89px] py-[40px] desktop:pl-[89px] pl-6 desktop:pr-[49px] pr-6'>
                {/* <div className='w-full'> */}
                {vector == 1 && (
                    <div
                        className={`relative desktop:w-[412px]  md:w-[50%] w-[90%]`}>
                        <FreePassShape color={bgShape ? bgShape : "#FFEDB7"} />
                        <img alt='shape' className="absolute object-cover w-full h-[calc(100%-3px)] top-[1px]" src={image} />
                    </div>
                )}
                {/* </div> */}
                <div className='desktop:w-[814px] w-full desktop:mt-0 mt-5'>
                    <h2 style={{ color: isWhite ? '#FFFDEF' : 'black' }} className={`heading sm:mt-0 mt-5`}>{title}</h2>
                    <p style={{ color: isWhite ? '#FFFDEF' : 'black' }} className='mt-[18px] heading3 '>{desc}</p>
                    <div className="grid mt-[18px] md:grid-cols-2 grid-cols-1 col-span-5  gap-x-3 gap-y-6">
                        <InputBase isWhite={isWhite ? true : false} value={userInfo.first_name} onChange={handleChangeValue} nameInput="first_name" holder="First Name*" />
                        <InputBase isWhite={isWhite ? true : false} value={userInfo.last_name} onChange={handleChangeValue} nameInput="last_name" holder="Last name*" />
                        <InputBase isWhite={isWhite ? true : false} value={userInfo.email} onChange={handleChangeValue} nameInput="email" holder="Email address*" />
                        <InputBase isWhite={isWhite ? true : false} value={userInfo.phone} onChange={handleChangeValue} nameInput="phone" holder="Phone Number*" />
                        <DropdownBase
                            holder="Location*"
                            color={isWhite ? 'white' : "black"}
                            options={locations?.data}
                            location={userInfo.city}
                            setLocation={selectedLocation => setUserInfo({ ...userInfo, city: selectedLocation })}
                        />
                    </div>
                    <div className="mt-[48px]  pr-0 flex justify-end">
                        <div onClick={handleSubmit}>
                            <Active text="ENQUIRE NOW" />
                        </div>
                    </div>
                </div>
            </div>
            {/* Modal Succes Get Freepass */}
            {status && <Succes open={status} setOpen={setStatus} />}
        </div >
    )
}

export default GetYourFreePass
